import React from "react"
import { useIntl } from "gatsby-plugin-intl"

//Components
import Layout from "../components/layout"
import Seo from "../components/seo"

export default () => {
  const intl = useIntl()
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "privacy-policy__title" })} />
      <div
        style={{
          backgroundColor: "#65A6FB",
          height: "92.5vh",
          textAlign: "center",
        }}
      >
        <h1 style={{ wordBreak: "break-word" }}>
          {intl.formatMessage({ id: "privacy-policy__title" })}
        </h1>
        <p style={{ marginTop: "1em", fontWeight: "bold", padding: "0 5%" }}>
          {intl.formatMessage({ id: "privacy-policy__content" })}
        </p>
      </div>
    </Layout>
  )
}
